<template>
  <Modal
      :visible="true"
      id="modal-export-member-report"
      size="lg"
      :title="$t('members.modal_export_member_report.title')"
      @close="$emit('close')">
    <p class="title">{{$t('members.modal_export_member_report.txt01')}}</p>

    <b-form @submit.stop.prevent>
      <label for="export_emails">
        {{$t('members.modal_export_member_report.label')}}
      </label>

      <MultiselectPlay
          v-model="emails"
          ref="multiselect_export"
          label="name"
          track-by="code"
          :placeholder="$t('members.modal_export_member_report.placeholder')"
          :options="options"
          :max=6
          :limit=6
          :multiple="true"
          :taggable="true"
          :tag="addEmail"
          :error="error"
          :hide-selected="true"
          :maxHeight=0
          :closeOnSelect="false"
          :preventAutofocus="false"
      />
    </b-form>

    <template slot="footer">
      <div class="flex-row justify-end items-center gap-1">
        <ButtonPlay
            type="normal"
            bgColor="transparent"
            bgColorOnHover="transparent"
            color="var(--maincolor)"
            @click="$emit('close')"
            :text="$t('members.modal_export_member_report.cancel')"
        />
        <ButtonPlay
            type="normal"
            @click="submit"
            :text="$t('members.modal_export_member_report.submit')"
            :loading="loading"
            loader="spinner"
        />
      </div>
    </template>
  </Modal>
</template>
<script>
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      emails: [],
      options: [],
      error: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
    })
  },
  watch: {
    emails(val) {
      this.error = null;

      if (val.length > 5) {
        this.error = this.$t('members.modal_export_member_report.email_limit');
      }
    }
  },
  methods: {
    validateEmail(email) {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return pattern.test(email)
    },

    addEmail (newTag) {
      if (!this.validateEmail(newTag)) {
        this.error = this.$t('members.modal_export_member_report.email_invalid', {
          email: newTag
        });
        return;
      }

      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.emails.push(tag)
    },

    getMemberEmail() {
      return this.getMe?.current?.currentMember?.email || null;
    },

    submit() {
      if (this.error) {
        return false;
      }

      if (this.emails.length === 0) {
        this.error = this.$t('members.modal_export_member_report.email_required');
        return false;
      }

      this.requestMembersReport()
    },

    requestMembersReport() {
      this.loading = true

      const emails = this.emails.map(i => i.name)
      const query = new URLSearchParams(window.location.search).toString();
      const data = {
        id: "export",
        exportData: query,
        emails
      };

      serviceMember
          .postID(data)
          .then(() => {
            this.$root.$bvToast.toast(this.$t("members.texts.txt76"), {
              title: this.$t("members.texts.txt79"),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            this.$emit('close')
          })
          .catch(() => {
            this.$bvToast.toast(this.$t("members.texts.txt77"), {
              title: this.$t("members.texts.txt80"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .finally(() => {
            this.loading = false
          });
    },
  },
  mounted() {
    this.addEmail(this.getMemberEmail())
  }
};
</script>
<style lang="scss">
#modal-export-member-report {
  .modal-dialog {
    width: 696px;
  }

  .header .headerTitle {
    font-weight: 600;
    font-size: 20px;
  }

  .content {
    margin-bottom: 40px;
  }

  .container_wrap {
    display: none;
  }

  .title, label {
    color: #B5B5B5;
    font-size: 16px;
    font-weight: 400;
  }

  .title {
    margin-bottom: 40px;
  }

  label {
    color: white;
    font-weight: 600;
  }

  .multiselect__input {
    font-weight: 400;
  }
}

[data-theme="light"] {
  #modal-export-member-report {
    .title {
      color: var(--neutral-gray-700);
    }

    label {
      color: var(--neutral-gray-900);
    }
  }
}
</style>
